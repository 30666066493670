<div
  class="app-splash-screen"
  [ngStyle]="{ 
    left: windowWidth, 
    opacity: opacityChange,
    transition: splashTransition
  }"
  *ngIf="showSplash">
  <div class="app-splash-inner">
    <div class="d-flex justify-content-center align-items-center" style="margin-top: -1em;margin-left: -1em;"
      [hidden]="!showItems">
      <div class="app-loader"></div>
      <img src="/assets/images/logo-ufcat-branca.webp" style="height: 8em;
      margin-top: 1em;
      margin-left: -4em;" alt="">
      <br><br>
      <app-logo class="mt-5 text-white" style="transform: scale(1.1);"></app-logo>
    </div>
    <i class="fal fa-2x fa-spin text-white mt-5 fa-circle-notch"></i>
  </div>
</div>