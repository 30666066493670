// src/app/pipes/enum-text.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumText'
})
export class EnumTextPipe implements PipeTransform {
  transform(value: string, options: { value: string, text: string }[]): string {
    const option = options.find(option => option.value === value);
    return option ? option.text : value;
  }
}
