import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ocultarNome'
})
export class OcultarNomePipe implements PipeTransform {

  transform(value: string): string {
    const nameParts = value.split(' ');
    if (nameParts.length > 2) {
      // Capitalizar o primeiro e último nome e ocultar os nomes do meio
      return `${this.capitalize(nameParts[0])} ****** ${this.capitalize(nameParts[nameParts.length - 1])}`;
    }
    return this.capitalize(value); // Caso o nome tenha menos de 3 partes
  }

  // Função para capitalizar a string
  private capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
