<button
  [type]="type"
  [id]="id"
  [disabled]="isDisabled"
  [class]="class"
  class=" br-button primary mr-3 small"
  [ngStyle]="{'width': buttonWidth }"
  [disabled]="isDisabled"
  [ngClass]="{
    'block': buttonWidth === 'full',
    'w-fit': buttonWidth === 'fit',
  }">
  <span *ngIf="!isLoading">
    <i [class]="icon + ' ' + 'mr-2'" *ngIf="icon && iconPosition === 'left'"></i>
    <ng-content></ng-content>
    <i [class]="icon + ' ' + 'ml-2'" *ngIf="icon && iconPosition === 'right'"></i>
  </span>
  <span *ngIf="isLoading">
    <i class="fas fa-spinner fa-spin"></i>
  </span>
</button>