import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'shared/_guards/auth.guard';

const routes: Routes = [
  { path: 'acesso', loadChildren: () => import('./acesso/acesso.module').then(m => m.AcessoModule) },
  { path: 'candidato', loadChildren: () => import('./candidato/candidato.module').then(m => m.CandidatoModule), canActivate:[AuthGuard] },
  { path: '', loadChildren: () => import('./processos/processos.module').then(m => m.ProcessosModule) },
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
