import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import * as Sentry from "@sentry/angular";

import { HashLocationStrategy, LocationStrategy, registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import ptBr from '@angular/common/locales/pt';
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouterModule } from "@angular/router";
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideEnvironmentNgxMask,
} from "ngx-mask";
import { ToastrModule } from "ngx-toastr";
import { ComponentsModule } from "shared/_components/components.module";
import { environment } from "../environments/environment";
import { LayoutModule } from "./_layout/layout.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent,
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    ComponentsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ToastrModule.forRoot(),
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [provideEnvironmentNgxMask(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, 
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    Location, { provide: LocationStrategy, useClass: HashLocationStrategy },
  { provide: LOCALE_ID, useValue: "pt" },
  { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
