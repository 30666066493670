<nav class="navbar navbar-expand-lg navbar-dark">
  <div class="container">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Alternar navegação">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <!-- Concursos/Processos Seletivos Dropdown -->
        <li class="nav-item dropdown" (mouseover)="toggleDropdown('concursosDropdown', true)"
          (mouseleave)="toggleDropdown('concursosDropdown', false)">
          <a class="nav-link dropdown-toggle" href="#" role="button" id="concursosDropdown" aria-expanded="false">
            Concursos/Processos Seletivos
          </a>
          <ul class="dropdown-menu" [class.show]="dropdownStates['concursosDropdown']">
            <li>
              <a class="dropdown-item" 
                 [routerLink]="['/processos']" 
                 [queryParams]="{ tipo: 'CONCURSO', status: 'aberto' }">
                Em andamento
              </a>
            </li>
            <li>
              <a class="dropdown-item" 
                 [routerLink]="['/processos']" 
                 [queryParams]="{ tipo: 'CONCURSO', status: 'encerrado' }">
                Encerrados
              </a>
            </li>
          </ul>
        </li>

        <!-- Estude na UFCAT Dropdown -->
        <li class="nav-item dropdown" (mouseover)="toggleDropdown('estude', true)"
          (mouseleave)="toggleDropdown('estude', false)">
          <a class="nav-link dropdown-toggle" href="#" role="button" id="estude" aria-expanded="false">
            Estude na UFCAT
          </a>
          <ul class="dropdown-menu" [class.show]="dropdownStates['estude']">
            <li>
              <a class="dropdown-item" 
                 [routerLink]="['/processos']" 
                 [queryParams]="{ tipo: 'GRADUAÇÃO' }">
                Graduação
              </a>
            </li>
          </ul>
        </li>

        <!-- Outros itens da navbar -->
        <li hidden class="nav-item">
          <a class="nav-link" routerLink="/contato">Contato</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
