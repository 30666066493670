import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly DELETE_KEY = 'dataDeleted';  // Chave que indica quando os dados foram apagados

  constructor() {}

  // Verifica se os dados já foram apagados e, se não, apaga e registra
  checkAndDeleteData(deleteDateTimeString: string): void {
    const deletedData = localStorage.getItem(this.DELETE_KEY);

    // A data e hora fornecida como string
    const deleteDateTime = new Date(deleteDateTimeString);

    // Se os dados já foram apagados, verifica se foi no dia correto e após a hora configurada
    if (deletedData) {
      const lastDeletedDate = new Date(deletedData);

      // Se os dados já foram apagados após o tempo configurado, não faz nada
      if (lastDeletedDate >= deleteDateTime) {
        console.log('Os dados já foram apagados após a data e hora configuradas.');
        return;
      }
    }

    const currentTime = new Date();

    // Se a hora atual for antes da data e hora configurada, não apaga ainda
    if (currentTime < deleteDateTime) {
      console.log('Ainda não é hora de apagar os dados.');
      return;
    }

    // Se for a hora ou depois, apaga os dados do localStorage e registra
    this.clearLocalStorage();
    localStorage.setItem(this.DELETE_KEY, new Date().toISOString());  // Registra o momento em que os dados foram apagados
    console.log('Os dados foram apagados com sucesso.');
  }

  // Limpa todos os dados do localStorage, exceto o registro de que os dados foram apagados
  private clearLocalStorage(): void {
    localStorage.removeItem('concursoSelecionado');
    localStorage.removeItem(this.DELETE_KEY);  // Remove a flag de que já foi apagado para reaplicar depois
  }
}
