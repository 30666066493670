// src/app/services/auth.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MOTHERS_NAMES } from 'projects/candidato/src/app/acesso/recuperar-senha/mother-names';
import { Pessoa } from 'shared/_model/pessoa';
import { Usuario } from 'shared/_model/usuario';
import { SupabaseService } from './supabase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private supabaseService: SupabaseService, private router: Router) { }

  async signIn(email: string, password: string) {
    try {
      const { data, error } = await this.supabaseService.client.auth.signInWithPassword({ email, password });
      if (error) throw new Error(error.message);
      let userData: Usuario | null = data.user ? await this.getUserByEmail(email) : null;
      if (!userData) {
        await this.registerUser(email, data.user!.id);
        userData = await this.getUserById(data.user!.id);
      } else if (userData?.id_usuario !== data.user!.id) {
        // Update user ID in the database
        await this.updateUserId(userData.id_usuario, data.user!.id);
        userData = await this.getUserById(data.user!.id);
      }

      localStorage.setItem('supabase_token', data.session?.access_token || '');
      this.updateLocalUser({ ...data.user, ...userData });
    } catch (error) {
      console.error(error);
      throw error
    }
  }

  private updateLocalUser(user: any) {
    const currentUser = JSON.parse(localStorage.getItem('user')!);
    localStorage.setItem('user', JSON.stringify({ ...currentUser, ...user }));
    localStorage.setItem('user_id', currentUser?.id_usuario || user?.id_usuario);
  }

  async signUp(email: string, password: string, nome?: string) {
    try {
      const { data, error } = await this.supabaseService.client.auth.signUp({
        email,
        password,
        options: {
          data: {
            first_name: nome,
            confirmation_sent_at: Date.now(),
          }
        }
      });
      if (error) throw error;

      await this.registerUser(email, data.user!.id, nome);
      return data.user;

    } catch (error) {
      console.error('Sign up error:', error);
      throw error;
    }
  }

  async signOut() {
    await this.supabaseService.client.auth.signOut();
    localStorage.removeItem('supabase_token');
    localStorage.removeItem('user');
    this.router.navigate(['/acesso']);
  }

  async registerUser(email: string, userId: string, nome?: string) {
    const userInsert = { id_usuario: userId, email, nome };
    await this.supabaseService.client.from('usuario').insert(userInsert);

    const personInsert = { id_usuario: userId, nome, email };
    await this.supabaseService.client.from('pessoa').insert(personInsert);
  }

  async updateUserId(oldUserId: string, newUserId: string) {
    await this.supabaseService.client.from('usuario')
      .update({ id_usuario: newUserId })
      .eq('id_usuario', oldUserId);

    await this.supabaseService.client.from('pessoa')
      .update({ id_usuario: newUserId })
      .eq('id_usuario', oldUserId);
  }

  async getUserById(userId: string): Promise<Usuario | null> {
    const { data, error } = await this.supabaseService.client
      .from('usuario')
      .select('*, pessoa(*), unidade_academica_usuario(id_unidade_academica)')
      .eq('id_usuario', userId)
      .single();

    if (error || !data) return null;
    return data as Usuario;
  }

  async getUserByEmail(email: string): Promise<Usuario | null> {
    const { data, error } = await this.supabaseService.client
      .from('usuario')
      .select('*, pessoa(*)')
      .eq('email', email)
      .single();

    if (error || !data) return null;
    return data as Usuario;
  }

  async getUserBasicByEmail(email: string): Promise<Usuario | null> {
    const { data, error } = await this.supabaseService.client
      .from('usuario')
      .select('email, pessoa(nome_mae)')
      .eq('email', email)
      .single();

    if (error || !data) return null;
    return data as any;
  }

  async getCurrentUser(): Promise<Usuario | undefined> {
    const userSavedString = localStorage.getItem('user');
    if (!userSavedString) return undefined;

    const userSaved = JSON.parse(userSavedString);
    const freshUser = await this.getUserById(userSaved.id_usuario);
    this.updateLocalUser({ ...userSaved, ...freshUser });
    const updatedUserString = localStorage.getItem('user');
    return updatedUserString ? JSON.parse(updatedUserString) : null;
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('supabase_token');
  }


  async registerCompleteUser(pessoa: Pessoa): Promise<Usuario | null> {
    try {
      // Check if the user already exists in the authentication system
      let user = await this.getUserByEmail(pessoa.email!);

      if (user) {
        // If user exists, sign them in and update the user details
        const { data, error } = await this.supabaseService.client.auth.signInWithPassword({
          email: pessoa.email!,
          password: pessoa.senha!
        });
        if (error) throw error;

        // Update user details in the usuario and pessoa tables
        const userId = data.user!.id;
        await this.updateUserDetails(userId, pessoa.email!, pessoa.nome!);
        user = await this.getUserById(userId);
      } else {
        // If user does not exist, sign them up
        const { data, error } = await this.supabaseService.client.auth.signUp({
          email: pessoa.email!,
          password: pessoa.senha!,
          options: {
            data: { name: pessoa.nome }
          }
        });
        if (error) throw error;

        // Use the ID from the Supabase authentication to create entries in usuario and pessoa tables
        const userId = data.user!.id;
        const userInsert = { id_usuario: userId, email: pessoa.email, nome: pessoa.nome };
        await this.supabaseService.client.from('usuario').insert(userInsert);
        const { senha, confirmacaoSenha, ...rest } = pessoa;
        await this.registerPerson(userId, { ...rest, id_usuario: userId });
        user = await this.getUserById(userId);
      }

      localStorage.removeItem('cadastroForm');
      return user;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  }

  async updateUserDetails(userId: string, email: string, name: string) {
    // Update user details in usuario table
    await this.supabaseService.client.from('usuario')
      .update({ email })
      .eq('id_usuario', userId);

    // Update user details in pessoa table
    await this.supabaseService.client.from('pessoa')
      .update({ nome: name, email })
      .eq('id_usuario', userId);
  }

  async registerPerson(userId: string, pessoa: Pessoa) {
    const personInsert: Pessoa = { ...pessoa, id_usuario: userId };

    await this.supabaseService.client
      .from('pessoa')
      .upsert(personInsert, { onConflict: 'id_usuario' });
  }

  hasRole(roles: string[], roleToCheck: string): boolean {
    return roles.includes(roleToCheck);
  }

  async validateSecurityQuestions(email: string, cpf: string, selectedName: string): Promise<boolean> {
    try {
      // Realiza uma consulta no banco para verificar se há um usuário com o e-mail, CPF e nome da mãe informados
      const { data, error } = await this.supabaseService.client
        .from('pessoa')
        .select('id_usuario')
        .eq('email', email)
        .eq('cpf', cpf)
        .eq('nome_mae', selectedName)
        .single();

      // Verifica se a consulta encontrou algum registro
      if (error || !data) {
        throw new Error('CPF ou nome da mãe incorreto');
      }

      // Se encontrou o registro, significa que os dados estão corretos
      return true;
    } catch (error) {
      console.error('Erro na validação das perguntas de segurança:', error);
      throw new Error('Falha na verificação das perguntas de segurança');
    }
  }

  async updatePassword(email: string, newPassword: string): Promise<void> {
    try {
      const user = await this.getUserByEmail(email);
      if (!user) {
        throw new Error('Usuário não encontrado');
      }

      // Atualizar a senha do usuário
      const { error } = await this.supabaseService.client.auth.admin.updateUserById(user.id_usuario, {
        password: newPassword
      });

      if (error) {
        debugger
        throw new Error('Erro ao atualizar a senha');
      }

      // Atualizar a senha no banco de dados, se necessário
      await this.supabaseService.client
        .from('pessoa')
        .update({ senha: newPassword })
        .eq('id_usuario', user.id_usuario);

    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async generateMotherNameOptions(correctName: string): Promise<string[]> {
    // Gerar duas opções aleatórias da lista e incluir o nome correto
    const shuffledNames = MOTHERS_NAMES
      .filter(name => name !== correctName)  // Remover o nome correto
      .sort(() => Math.random() - 0.5);      // Misturar a lista

    const options = [correctName, shuffledNames[0], shuffledNames[1], shuffledNames[2], shuffledNames[3]].sort(() => Math.random() - 0.5);
    return options;
  }
  async validateMotherName(email: string, selectedName: string): Promise<boolean> {
    const user = await this.getUserByEmail(email);
    if (!user || user.pessoa!.nome_mae !== selectedName) {
      throw new Error('Nome da mãe incorreto');
    }
    return true;
  }

}
