import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  @Input() subtitle?: string=undefined;
  @Input() bg?: boolean=false;
  @Input() inverse?: boolean=false;
  goToHome(){
    window.location.href = '/';
  }
}
