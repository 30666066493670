<style>
  header {
    background-color: white;
  }

  li {
    margin-inline-end: 8px;
    font-size: 12px;
    border-radius: 4px;
    a{
      border-radius: 4px;
    }
    &.active {
      background-color: #007575 !important;

      * {
        color: #fafafa;
      }
    }
  }
</style>

<header class="navbar-expand-md border-bottom">
  <div class="container-xl">
    <div class="collapse navbar-collapse" id="navbar-menu">
      <div class="navbar">
        <div class="container-xl">
          <div class="row flex-fill align-items-center">
            <div class="col d-flex">
              <ul class="navbar-nav">
                <li class="nav-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">
                  <a class="nav-link" [routerLink]="['/']">
                    <span
                      class="nav-link-icon d-md-none d-lg-inline-block">
                      <i class="fal fa-home"></i> Processos
                    </span>
                  </a>
                </li>
              </ul>
              <ul class="navbar-nav" *ngIf="user">
                <li class="nav-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">
                  <a class="nav-link" [routerLink]="['/candidato']">
                    <span
                      class="nav-link-icon d-md-none d-lg-inline-block">
                      <i class="fal fa-user"></i>
                    </span>
                    <span class="nav-link-title">
                      Meus dados
                    </span>
                  </a>
                </li>
                <li class="nav-item " hidden [routerLinkActive]="'active'">
                  <a class="nav-link" [routerLink]="['/candidato/cadastro']">
                    <span
                      class="nav-link-icon d-md-none d-lg-inline-block">
                      <i class="fal fa-user"></i>
                    </span>
                    <span class="nav-link-title">
                      Meu cadastro
                    </span>
                  </a>
                </li>
                <li class="nav-item " [routerLinkActive]="'active'">
                  <a class="nav-link" [routerLink]="['/candidato/inscricoes']">
                    <span
                      class="nav-link-icon d-md-none d-lg-inline-block">
                      <i class="fal fa-tasks"></i>
                    </span>
                    <span class="nav-link-title">
                      Minhas inscrições
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>