
export const environment = {
  env: 'local',
  production: false,
  //supabaseUrl: 'https://portaldeselecao.ufcat.edu.br/api/',
  supabaseUrl: 'https://sisconcurso-dev.ufcat.edu.br/supabase/',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogInNlcnZpY2Vfcm9sZSIsCiAgImlzcyI6ICJzdXBhYmFzZSIsCiAgImlhdCI6IDE3MTYxNzQwMDAsCiAgImV4cCI6IDE4NzM5NDA0MDAKfQ.Fh0HpnmcJLqdrcygzIp0Dxx4gyvikOPieZpjesfREpY',
  firebase: {
    apiKey: "AIzaSyDNRZLA9fz79SoIIpaI-pOf5ds8Wtyh42M",
    authDomain: "portal-de-selecao.firebaseapp.com",
    projectId: "portal-de-selecao",
    storageBucket: "portal-de-selecao.appspot.com",
    messagingSenderId: "608620164558",
    appId: "1:608620164558:web:1910bf6fe94ebd40a9e79f",
    measurementId: "G-9FP81591NF"
  }
};
