import { Component } from '@angular/core';
import { AuthService } from 'shared/_services/auth.service';

@Component({
  selector: 'app-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {
  user: any;
  dropdownStates: { [key: string]: boolean } = {};

  constructor(public authService: AuthService) {
  }

  toggleDropdown(dropdown: string, state: boolean) {
    this.dropdownStates[dropdown] = state;
  }
  async ngOnInit(): Promise<void> {
    this.user=  await this.authService.getCurrentUser()
  }
}
