import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { UploadService } from 'shared/_services/upload.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
  @Input() text: string;
  @Output() fileUploaded: EventEmitter<string> = new EventEmitter<string>();
  files: File[] = [];
  filesUploadedTmp: any = []

  constructor(private uploadService: UploadService) { }

  onSelect(event: NgxDropzoneChangeEvent) {
    this.files.push(...event.addedFiles);
    // Upload each file and log the URL
    this.files.forEach(file => {
      this.uploadService.uploadFile(file).then(file => {
        //console.log('File file:', file);
        this.filesUploadedTmp=[file]
        this.fileUploaded.emit(file);
      }).catch(error => {
        console.error('Error uploading file:', error);
      });
    });
  }
}