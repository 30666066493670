import { Component, inject } from '@angular/core';
import { Analytics } from '@angular/fire/analytics';
import { RouterOutlet } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { StorageService } from 'shared/_services/storage.service';
import { fader } from 'shared/_util/route-animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fader]
})
export class AppComponent {
  private analytics = inject(Analytics);


  title = 'Portal Seleção';
  constructor(private config: NgSelectConfig,private storageService: StorageService) {
    this.config.notFoundText = 'Não encontrado';
    this.config.appendTo = 'body';
    this.config.placeholder = 'Selecione';
    this.config.bindValue = 'value';
    const deleteDateTime = '2024-10-15T10:00:00';  
    this.storageService.checkAndDeleteData(deleteDateTime);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}
