<div class="row">
  <div class="col">
    <div class="bg-light border-top border-bottom" style="--bs-border-color: rgb(222 231 249/1);">
      <div class="container w-100 d-flex">
        <div class="pb-2 w-100 pt-1 title ps-0">
          <div class="row">
            <div class="col d-flex justify-content-center flex-column">
              <h6 [ngClass]="{'text-center': centered}" class="my-4 text-info pb-0"><ng-content></ng-content></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>