<div class="border-bottom bg-light p-2 border mb-2">
  <ngx-dropzone (change)="onSelect($event)">
    <ngx-dropzone-label>{{text || 'Arraste e solte seus arquivos aqui ou clique para carregar'}}</ngx-dropzone-label>
  </ngx-dropzone>
  
  <div class="d-flex">
    <div *ngFor="let file of filesUploadedTmp">
      <a class="card p-2  w-auto" target="_blank" style="cursor: pointer;" [href]="file.url">{{file.nome}}</a>
    </div>
  </div>
</div>