<div class="d-flex" [ngClass]="{'my-2': subtitle, 'bg': bg}">
  <div id="marca">
    <div (click)="goToHome()" class="pt-0 mt-2 px-3" style="cursor: pointer;    line-height: 0px;">
      <span style="font-weight: 400;color: #f97420;letter-spacing: 0px; font-size: 14px;">PORTAL de </span>
      <h4 class="my-0 mt-n2 p0 text-uppercase" [ngClass]="{'inverse': inverse}"
        style="letter-spacing: 1px;  font-weight: 800; font-size: 25px;">SELEÇÃO</h4>
    </div>
  </div>
  <div *ngIf="subtitle" class="fw-light mb-0"
    style="font-size: 13px; cursor: default; padding-top: 1px; line-height: 20px; padding-left: 1em; margin-top: 6px; margin-left: -4px; border-left: 4px solid #0b3357;">
    <h6 class="py-2 m-0 fw-bold">{{subtitle}}</h6>
  </div>
</div>