import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SupabaseService } from './supabase.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private supabaseService: SupabaseService, private toastrservice: ToastrService) { }

  async uploadFile(file: File): Promise<any> {
    const timestamp = new Date().getTime();
    const fileName = this.formatFileName(file.name);
    const { data, error } = await this.supabaseService.client.storage.from('uploads').upload(`public/${timestamp}-${fileName}`, file);

    if (error) {
      throw error;
    }

    const fileUrl = this.supabaseService.client.storage.from('uploads').getPublicUrl(data.path).data.publicUrl;

    const { data: insertData, error: insertError } = await this.supabaseService.client
      .from('link')
      .insert({ created_by: localStorage.getItem('user_id'), nome: file.name, link: fileUrl, tipo: 'COMPROVACAO' })
      .select('id_link')
      .single();

    if (insertError) {
      throw insertError;
    }

    return { url: fileUrl, id_link: insertData.id_link, nome: fileName };
  }

  private formatFileName(fileName: string): string {
    // Remover acentos e caracteres especiais
    fileName = fileName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Substituir espaços por underscores
    fileName = fileName.replace(/\s+/g, '_');

    // Remover caracteres especiais que não são permitidos em nomes de arquivo
    fileName = fileName.replace(/[^a-zA-Z0-9_\-.]/g, '');

    return fileName;
  }
}