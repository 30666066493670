import { Component } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
 styleUrls: [ './breadcrumb.component.scss']
})
export class BreadcrumbComponent {

}
