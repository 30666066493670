<style>
  footer {
    border: none;
  }

  a {
    margin-inline: 1em;
    text-decoration: none;

    span {
      color: #8a8383;
    }
  }
</style>
<footer class="br-footer " style="position: sticky;
width: 100%;
background: white;
border-top: 1px solid #cacaca;
bottom: 0;">
  <div class="container-lg p-2 d-flex justify-content-center">
    <div clas="d-flex w-100 justify-content-between start center"
      style="flex-direction: row; box-sizing: border-box; max-height: 100%; place-content: center flex-start; align-items: center; display: flex;">
      <a
        href="http://portal.ufcat.edu.br" target="_blank"
        style="display: block;" tabindex="0" aria-disabled="false">
        <span>Desenvolvido pela Secretaria de Tecnologia da Informação - UFCAT</span>
      </a>
      <span>•</span>
      <a class="no-click mat-button" tabindex="0"
        aria-disabled="false">
        <span>SETI</span>
      </a>
      <span>•</span>
      <a class="no-click mat-button"
        tabindex="0" aria-disabled="false">
        <span>V: {{version}} </span>
      </a>
    </div>
  </div>
</footer>