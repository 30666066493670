<div class="container-lg">
<div class="br-breadcrumb">
  <ul class="crumb-list">
    <li class="crumb home" [routerLink]="['/']" >
      <div class="br-button circle"><span class="sr-only">Página inicial</span><i class="icon fas fa-home"></i></div>
    </li>
    <li class="crumb" data-active="active"><i class="icon fas fa-chevron-right"></i><span>
      <ng-content ></ng-content>
    </span>
    </li>
  </ul>
</div>
</div>
<hr class="m-0">