<div class="text-white border-right" id="sidebar-wrapper">
  <div class="list-group pt-5 list-group-flush">
    <a [routerLink]="['/']"  [routerLinkActive]="'active'"  >
      <span> <i class="fal fa-tasks"></i> Processos</span>
    </a>
    <a [routerLink]="['/unidades']" [routerLinkActive]="'active'"  >
      <span>  <i class="fal fa-building"></i> Unidades Acadêmicas</span>
    </a>
    <a [routerLink]="['/relatorios']"  [routerLinkActive]="'active'"  >
      <span> <i class="fal fa-users"></i> Inscrições </span>
    </a>
    <a [routerLink]="['/relatorios']"  [routerLinkActive]="'active'"  >
      <span> <i class="fal fa-bullhorn"></i> Solicitações </span>
    </a>
    <a [routerLink]="['/relatorios']"  [routerLinkActive]="'active'"  >
      <span> <i class="fal fa-sticky-note"></i> Relatórios</span>
    </a>
  </div>
</div>