import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { fader } from 'shared/_util/route-animations';
import { Analytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fader]
})
export class AppComponent {
  private analytics = inject(Analytics);

  title = 'Portal Seleção';
  constructor(private config: NgSelectConfig) {
    this.config.notFoundText = 'Não encontrado';
    this.config.appendTo = 'body';
    this.config.placeholder = 'Selecione';
    this.config.bindValue = 'value';
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}
