import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { commitHash, version } from 'shared/version';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  version=version+' - '+commitHash;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  
  }
}
