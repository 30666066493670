import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from 'shared/_components/components.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TitleComponent } from './title/title.component';

@NgModule({
  declarations: [
    TitleComponent, BreadcrumbComponent, NavbarComponent, SidebarComponent
  ],
  imports: [
    CommonModule, RouterModule, ComponentsModule
  ],
  exports:[
    TitleComponent, BreadcrumbComponent, NavbarComponent, SidebarComponent
  ]
})
export class LayoutModule { }
