import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { EnumTextPipe } from 'shared/_pipes/enum-text.pipe';
import { OcultarNomePipe } from 'shared/_pipes/ocultar-nome.pipe';
import { ButtonComponent } from './button/button.component';
import { FooterComponent } from './footer/footer.component';
import { LogoComponent } from './logo/logo.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { UploadComponent } from './upload/upload.component';

const components = [ButtonComponent, SplashScreenComponent, UploadComponent, LogoComponent, EnumTextPipe, OcultarNomePipe, FooterComponent]

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule, RouterModule, NgxDropzoneModule,
  ],
  exports: [
    ...components
  ]
})
export class ComponentsModule { }
