export const MOTHERS_NAMES = [
  'Maria Clara Souza Oliveira',
  'Ana Paula Santos Ferreira',
  'Joana Almeida Silva',
  'Clara Oliveira Lima',
  'Helena Pereira Costa',
  'Carolina Costa Mendes',
  'Luciana Martins Ribeiro',
  'Patrícia Ramos de Souza',
  'Fernanda Ribeiro Almeida',
  'Gabriela Figueiredo Machado',
  'Sebastiana Gonçalves Moreira',
  'Juliana Santos Costa',
  'Marcela Alves de Souza',
  'Renata Ferreira Lima',
  'Camila Oliveira Santos',
  'Tatiana Rodrigues Almeida',
  'Daniela Silva Martins',
  'Amanda Figueiredo Costa',
  'Simone Pereira dos Santos',
  'Cristiane Ribeiro da Silva',
  'Fabiana Teixeira de Souza',
  'Vanessa Mendes Oliveira',
  'Roberta Almeida Santos',
  'Raquel Lima dos Santos',
  'Bianca Martins Ferreira',
  'Mônica Souza Ribeiro',
  'Sabrina Oliveira de Souza',
  'Débora Santos Pereira',
  'Adriana Ribeiro Almeida',
  'Eliane Costa Figueiredo',
  'Larissa Silva Nogueira',
  'Joaquina Alves de Souza',
  'Francisca Nogueira da Silva',
  'Benedita Pereira dos Santos',
  'Luzia Martins de Almeida',
  'Antônia Silva Rodrigues',
  'Josefa Teixeira Ramos',
  'Cândida Araújo Figueiredo',
  'Adelina Ferreira de Lima',
  'Eulina Santos Cardoso',
  'Izabel Lima dos Reis',
  'Odete Ribeiro de Souza',
  'Francisca Oliveira Matos',
  'Ivone Mendes Ferreira',
  'Ilda Gonçalves de Souza',
  'Amélia Figueiredo Lima',
  'Carmelita Santos Rocha'
];
